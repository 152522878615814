import Edith_logo from '../icons/Edith_logo.svg';
import './../App.css';
import './styles.css';
// import NavBarComponent from './NavBarComponent';

function Header()
{
    return (
        <><div className="d-flex justify-content-between margin-page background-white" style={{ position: 'relative' }}>
            <div>
                 <img className="header-edith-icon" src={Edith_logo} alt="Edith logo" />
            </div>
            <div className="width-header-text header-contact">
                <div className="header-text-color font-weight-400">
                <img className="header-call-icon" src="icons/Header/call icon.svg" alt="call us"/>
                    Feel Free To Call Us :</div>
                <div className="header-text-color text3-size font-weight-500 header-contact-text">+91 8977530174, +91 8977630174.</div>
            </div>
             
        </div>
    {/* <img src={navbar_image} alt="Navbar" className="w-100" style={{ width: '100%', height: 'auto', zIndex: -1 }}/> */}
        {/* <NavBarComponent style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1 }}/> */}
        </>
    );
}

export default Header;