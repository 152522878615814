import './styles.css';
import OurValuesComponent from "./OurValuesComponent";
import './../styles/WhyEdith.css';
import './../styles/BoxWithText.css';

function WhyEdith()
{
    const isSmallScreen = window.innerWidth <= 768; // Define your breakpoint
    return(
        <>

        <div className={`${isSmallScreen ? 'pb-2': 'pb-3'} margin-page background-white`}>
             <img className={`w-100 ${ isSmallScreen ?  'px-2': 'px-3' } why-edith-image`} src="icons/WhyEdith/why edith title.svg" alt="Design"/>
        </div>

        {/* <BoxWithText img_src="icons/WhyEdith/01 Quote box.svg" text="Fully legal compliant company, ensuring transparency, trust, and ethical practices in everything we do."/>
        
        <BoxWithText img_src="icons/WhyEdith/quote 02 box.svg" text="Hassle-free home ownership with our end to end Financial guidance through the property buying process, offering home loan assistance to make your dream home a reality."/>
        <BoxWithText img_src="icons/WhyEdith/quote 03 box.svg" text="Quality construction and timely project delivery, driven by our architects, engineers, and project planning experts. Your satisfaction is our top priority."/>
        <BoxWithText img_src="icons/WhyEdith/quote 04 box.svg" text="Delivering results that exceed expectations. Our team includes reputed financial analysts who provide cost-benefit analysis to ensure your investment delivers the best value."/> */}


       {/* boxes code */}


       { isSmallScreen ?
        (
          <div className="d-flex margin-page background-white boxes-spacing">
            <div className="w-100"> 
              <img style={{'marginLeft':'6px'}} src="icons/WhyEdith/mobile quote box 1.svg" alt="quote box1" className="" />
            </div>
          </div>
        )
        :(
          <div className="d-flex justify-content-between margin-page background-white">
          <div className="box-container choose-edith-firstbox boxes-spacing">
            <img className="box-container-align box-image choose-edith-box" src="icons/WhyEdith/01 Quote box.svg" alt="Box" />
            <div className="text-overlay">
              <p className="text text-whyedith-page font-weight-400">Fully legal compliant company, ensuring transparency,<br/> trust, and ethical practices in everything we do.</p>
            </div>
          </div> 
          <div>
            <img src="icons/HomePage/boxes pattern grey.svg" alt="Top Left Image" className="grey-boxes greybox-first" />
          </div>


       </div>
        )
      }

      {
         isSmallScreen ? 
         (
          <div className="d-flex margin-page background-white boxes-spacing">
            <div className="w-100">
              <img style={{'marginLeft':'6px'}} src="icons/WhyEdith/mobile quote box 2.svg" alt="quote box1" className="" />
            </div>
          </div>  
         ):
         (
          <div className="d-flex justify-content-between margin-page background-white">
          <div>
               
          </div>
          <div className="box-container box-container-align boxes-spacing choose-edith-secondbox">
            <img className="box-container-align box-image  choose-edith-box" src="icons/WhyEdith/quote 02 box.svg" alt="Box" />
            <div className="text-overlay">
              <p className="text text-whyedith-page font-weight-400">Hassle-free home ownership with our end to end Financial guidance through the property buying process, offering home loan assistance to make your dream home a reality.</p>
            </div>
          </div>
         </div>
         )
      }


      {
         isSmallScreen ? 
         (
          <div className="d-flex margin-page background-white boxes-spacing">
            <div className="w-100">
              <img style={{'marginLeft':'6px'}} src="icons/WhyEdith/mobile quote box 3.svg" alt="quote box1" className="" />
            </div>
          </div>  
         ):
         (
          <div className="d-flex justify-content-between margin-page background-white ">
            <div className="box-container box-container-align boxes-spacing choose-edith-thirdbox">
              <img className="box-container-align box-image  choose-edith-box" src="icons/WhyEdith/quote 03 box.svg" alt="Box"/>
              <div className="text-overlay">
                <p className="text text-whyedith-page font-weight-400">Quality construction and timely project delivery, driven by our architects, engineers, and project planning experts. Your satisfaction is our top priority.</p>
              </div>
            </div>
            <div>

            </div>
         </div>
         )
      }

      {
         isSmallScreen ? 
         (
          <div className="d-flex margin-page background-white section-separation">
            <div className="w-100">
              <img style={{'marginLeft':'6px'}} src="icons/WhyEdith/mobile quote box 4.svg" alt="quote box1" className="" />
            </div>
          </div>  
         ):
         (
            <div className="d-flex justify-content-between margin-page background-white section-separation">

              <div>
                <img src="icons/HomePage/boxes pattern grey.svg" alt="Top Left Image" className="grey-boxes greybox-last" />
              </div> 
              <div className="box-container box-container-align choose-edith-lastbox">
                <img className="box-container-align box-image  choose-edith-box" src="icons/WhyEdith/quote 04 box.svg" alt="Box"/>
                <div className="text-overlay">
                  <p className="text text-whyedith-page font-weight-400">Delivering results that exceed expectations. Our team includes reputed financial analysts who provide cost-benefit analysis to ensure your investment delivers the best value.</p>
                </div>
              </div>

           </div>
         )
      }


      {/* boxes code */}
        

        {/* Commit to excellence block */}

        <div className="margin-page background-white section-separation">
            <div><p className="color-heading font-weight-500 font-section-text">"Commitment to excellence"</p></div>

            <div>

              {
                  isSmallScreen ?
                  (
                    <p className="mt-3 text-black font-weight-400 commitment-text">
                    A dedicated team of experts, and a focus on innovation,<br/> we're your partner in  building the future you deserve. For the <br/>smart choice of construction and design that goes above <br/> and beyond. 
                    </p>
                  )
                  :
                  (
                    <p className="mt-3 text-black font-weight-400 commitment-text">
              
                    A dedicated team of experts, and a focus on innovation, we're your partner in <br/> building the future you deserve. For the smart choice of construction and<br/> design that goes above and beyond. 
                    </p>
                  )     
              }
              </div>

            <div className="mt-3 text-black font-weight-400 font-section-text"> <span>Choose "</span> 
                  <span style={{ color : '#32617F'}}>E</span>
                  <span style={{ color : '#84BA6F'}}>D</span>
                  <span style={{ color : '#000000'}}>ITH</span>
                  <span>"</span>
            
            </div>

        </div>


         {/* Our vision and mission */}

         <div className="margin-page background-white">
             <img className="our-vision-image" src="icons/WhyEdith/our vision mision title.svg" alt="Our vision and mision"/>
         </div>

        
          {
            isSmallScreen ? (
                <div className="margin-page background-white">
                  <div className="mb-2">
                    <img className="px-2 w-100" src="icons/WhyEdith/vission_mobile.svg" alt="vision"/>
                  </div>
                  <div className="d-flex">
                    <img className="px-2" src="icons/WhyEdith/vission_statement_mobile.svg" alt="vision"/>
                    <div className="vision-statement">
                        <p className="text-left font-weight-400 our-vision-text">To redefine the art of construction and design, becoming the epitome of innovation and excellence in the industry at global level. We envision a future where our creations inspire awe, elevate living standards, and leave a lasting legacy.</p>
                    </div>
                  </div>

                </div>
              ):
              (
                <div className="d-flex margin-page background-white">
                  <img className="px-3 w-100" src="icons/WhyEdith/visionStatement.svg" alt="vision"/>
                  <div className="vision-statement">
                      <p className="text-left font-weight-400 our-vision-text">To redefine the art of construction and design, becoming the epitome of innovation and excellence in the industry at global level. We envision a future where our creations inspire awe, elevate living standards, and leave a lasting legacy.</p>
                  </div>
                </div>
              )
          }


          {
            isSmallScreen ? (
                <div className="margin-page background-white padding-mission-block">
                  <div className="mb-2">
                    <img className="px-2 w-100" src="icons/WhyEdith/mission_mobile.svg" alt="vision"/>
                  </div>
                  <div className="d-flex">
                    <div className="mission-statement">
                      {/* discuss text margin */}
                      <p className="text-left font-weight-400 our-mission-text">To transform landscapes through meticulous planning, innovative designs, and unwavering commitment to quality. We're dedicated to delivering exceptional craftsmanship, enhancing lives, championing sustainable innovation, and fostering a customer-centric approach. Our team's continuous growth and community engagement drive us to set new standards of excellence in the construction and design industry, not just in Vizag but beyond borders.</p>
                    </div>
                    <img className="w-100" src="icons/WhyEdith/mission_statement_mobile.svg" alt="vision"/>
                  </div>

                </div>
              ):
              (
                <div className="d-flex margin-page background-white padding-mission-block"> 
                <div className="mission-statement">
                    {/* discuss text margin */}
                    <p className="text-left font-weight-400 our-mission-text">To transform landscapes through meticulous planning, innovative designs, and unwavering commitment to quality. We're dedicated to delivering exceptional craftsmanship, enhancing lives, championing sustainable innovation, and fostering a customer-centric approach. Our team's continuous growth and community engagement drive us to set new standards of excellence in the construction and design industry, not just in Vizag but beyond borders.</p>
                 </div>
                 <img className="px-3 w-100" src="icons/WhyEdith/missionStatement.svg" alt="mission"/>
                </div>
              )
          }


          
        

        {/* About Founders */}

        <div className="founders-block margin-page background-white">
        <div className="founder-heading"><span className="color-heading ">“Our Founders” </span></div>
        
          <div className="container-founder">
            <div className="centered-text-founder">
              {/* Your text goes here, you can use <br/> for multiple lines */}

              { isSmallScreen ? 
                (
                  <p className="font-weight-400 text-whyedith-page m-0">
                    One a Chartered Accountant with keen financial insight and <br/> 
                    the other an Architect with visionary design sensibilities,<br/> 
                    form the heart of our dynamic team. Our team is a closely-<br/>
                    knit family of experts, including civil engineers, architects,<br/>
                    financial advisors, and corporate professionals. Together,<br/> 
                    we're dedicated to crafting state-of-the-art infrastructure and<br/>
                    prestigious projects.
                  </p>
                ):
                (
                  <p className="font-weight-400 text-whyedith-page m-0">
                    One a Chartered Accountant with keen financial insight and the other an Architect with<br/>
                    visionary design sensibilities, form the heart of our dynamic team. Our team is a closely-<br/>
                    knit family of experts, including civil engineers, architects, financial advisors, and corporate<br/>
                    professionals. Together, we're dedicated to crafting state-of-the-art infrastructure<br/>
                    and prestigious projects.
                  </p>
                )
              }
              
            </div>
          </div>

        </div>


        

        {/* our values */}

        {/* discuss ask for image */}
        
        <OurValuesComponent className="our-values-section" />
        
        </>
        
    );

}

export default WhyEdith;