import React from 'react';
import './App.css'; 
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer.js';
import Header from './components/Header.js';
import HomePage from './components/HomePage.js';
import WhyEdith from './components/WhyEdith.js';
import NavBarComponent from './components/NavBarComponent.js';
import { useEffect,useState } from 'react';


function App() {
  // const [location, setLocation] = useState(null);

  // useEffect(() => {
  //   // Update the location whenever it changes
  //   setLocation(window.location.pathname);
  // }, []);
  return (
    <div className="App background-grey overflow-x-hidden">
      <Header/>
      {/* <NavBarComponent className="background-white" location={location}/> */}
      <Router>
      <NavBarComponent className="background-white"/>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/WhyEdith" element={<WhyEdith />}/>
          <Route path="*" element={<HomePage />} />
          {/* <Route path="*WhyEdith*" path="/contact" component={Contact} /> */}
        </Routes>
      </Router> 
      <Footer/>
      
    </div>
  );
}

export default App;
