import './styles.css';
import './../styles/footer.css';
function Footer(){
    const isSmallScreen = window.innerWidth <= 768; // Define your breakpoint
    return (
        <>
        <div className="margin-page background-white footer-extra-area">

        </div>
        <div className="footer-section">

         {/* style={{ 'marginBottom': '1rem' }} */}
          <div  className="text-align-right margin-providers">
              <span className="text2 font-weight-400" > Construction  &nbsp;&nbsp;| &nbsp;&nbsp;  Development &nbsp;&nbsp; |&nbsp;&nbsp; Designing</span>
          </div>

          <div className={`${isSmallScreen ? '':'d-flex justify-content-between'}`}>
             {/* company logo and social media */}
            <div className="footer-section-1">
              <div className={`${isSmallScreen ? 'text-align-start':''}`}>
                 <img className={`${isSmallScreen ? 'w-111':''}`} src="icons/Footer/Edith logo Footer.svg" alt="linkedin"/>
              </div>

              <div className="d-flex display-content flex-column">
                 {/* <p className="info-content font-weight-400 m-0"> “FROM THE CONCEPT <br/>TO CREATION” </p> */}
                 <p className="info-content font-weight-400 m-0"> “FROM THE CONCEPT </p>
                 <p className="info-content font-weight-400 m-0"> TO CREATION” </p>
               </div>

               <div className="d-flex display-content flex-column">
                <p className="text2 font-weight-400 m-0"> Shaping Landscapes, Creating Futures ! </p>
               </div>
            
               <div className="d-flex display-content flex-column margin-follow-us">
               <p className="follow-us-text font-weight-500">Follow us</p>
               </div>

               <div className="d-flex display-content">
                  <a className="mr-12" href="https://www.linkedin.com/company/102211512/admin/feed/posts/" target="_blank" rel="noopener noreferrer">
                    <img className="footer-image" src="icons/Footer/linked in icon.svg" alt="linkedin"/>
                  </a>
                  <a className="mr-12" href="https://www.instagram.com/edith_group_/" target="_blank" rel="noopener noreferrer">
                    <img className="footer-image" src="icons/Footer/insta icon.svg" alt="Insta"/>
                  </a>
                  <a className="mr-12" href="https://www.facebook.com/profile.php?id=61560141765633" target="_blank" rel="noopener noreferrer">
                    <img className="footer-image" src="icons/Footer/facebook icon.svg" alt="facebook"/>
                  </a>
                  <a className="mr-12" href="https://whatsapp.com/channel/0029Va8x2iZICVfje62YNL3M" target="_blank" rel="noopener noreferrer">
                    <img className="footer-image" src="icons/Footer/whats app icon white.svg" alt="whatsApp"/>
                  </a>
               </div>
            </div>

              {/* company contact */}

            <div className="footer-section-2">
                <span className="d-flex contact-us-text font-weight-500 contact-us-margin">CONTACT US - </span>

                <div className="d-flex margin-contactus-items align-items-center">
                   <img className="mr-12 footer-image home-icon" src="icons/Footer/home icon.svg" alt="address"/>
                   <span className="contact-text font-weight-400 margin-text"> Marripalem, VUDA Colony, Visakhapatnam - 530009</span>
                </div>

                <div className="d-flex margin-contactus-items align-items-center">
                  <img className="mr-12 footer-image phone-icon" src="icons/Footer/phonecall icon white.svg" alt="phone"/>
                   <span className="contact-text font-weight-500 margin-text"> +91 89775 30174, +91 89776 30174.</span>
                </div>
                <div className="d-flex align-items-center">
                  <img className="mr-12 footer-image mail-icon" src="icons/Footer/mail icon white.svg" alt="phone"/>
                   <span className="contact-text font-weight-500 margin-text"> contactedithgroup@gmail.com</span>
                </div>

            </div>

          </div>

          <div className="d-flex display-content flex-column margin-copyright">
           <img className="footer_building footer-building-image d-flex" src="icons/Footer/footer building image.svg" alt="building"/>

          </div>

          
          <p className="font-weight-400 m-0 copy-right-text">Copyright 2024 @EDITH | Privacy policy | Reserved rights. </p>
        </div>
        </>
      );
}

export default Footer;