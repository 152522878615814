import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import navbar_image from '../icons/Header/navigation bar.svg'
import navbar_image_mobile from '../icons/Header/navigation_bar_mobile.svg'
import { useLocation } from 'react-router-dom';


function scrollToBottom() {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth' // Optional: Smooth scrolling animation
  });
}

function NavBarComponent() {

  const isSmallScreen = window.innerWidth <= 768; // Define your breakpoint

  const location = useLocation();
  console.log(location);
  const imageContainerStyle = {
    position: 'relative',
    display: 'inline-block',
    'backgroundColor':'white'
  };
 
  // const overlayStyle = {
  //   position: 'absolute',
  //   top: '34%',
  //   right: '-15%',
  //   transform: 'translate(-50%, -50%)',
  //   padding: '20px',
  //   borderRadius: '10px',
  // };

  return (

    <>   
    <div className="margin-page" style={imageContainerStyle}>

    <img src={isSmallScreen ? navbar_image_mobile : navbar_image } alt="Navbar" className="nav-bar-width w-100" style={{ height: 'auto', zIndex: -1 }}/> 

     <div  className="overlay-nav-bar">
     {/* style={{ backgroundImage: `url(${isSmallScreen ? navbar_image_mobile : navbar_image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} */}
      <Navbar style={{ backgroundSize: 'cover', backgroundPosition: 'center' }}>
          {/* <Container>
            <Nav className="me-auto nav-text-color">
              <Nav.Link className={`font-weight-500 route-text ${location.pathname === '/' ? 'active-nav-link' : ''} nav-link-item route-link-margin`} href="/">Home</Nav.Link>
              <Nav.Link className={`font-weight-500 route-text ${location.pathname === '/WhyEdith' ? 'active-nav-link' : ''} nav-link-item route-link-margin`} href="/WhyEdith">Why Edith ?</Nav.Link>
              <Nav.Link className="font-weight-500 route-text nav-link-item" onClick={scrollToBottom}>Contact Us</Nav.Link>
            </Nav>
          </Container> */}


         <Container>
          <Nav className="me-auto nav-text-color">
          <Nav.Link className={`font-weight-500 route-text ${location.pathname === '/' ? 'active-nav-link' : 'nav-link-item-links'} nav-link-item route-link-margin`} href="/">Home</Nav.Link>
          <Link to="/WhyEdith" style={{ textDecoration: 'none' }} className={`font-weight-500 route-text nav-link-item route-link-margin whyedithlink ${location.pathname === '/WhyEdith' ? 'active-nav-link' : ''}`}>Why Edith ?</Link>
            <Nav.Link className="font-weight-500 route-text nav-link-item-links" onClick={scrollToBottom}>Contact Us</Nav.Link>
          </Nav>
         </Container>
      </Navbar>
     </div>
    </div>
    
    
      </>
  );
}

export default NavBarComponent;