import './styles.css';
import OurValuesComponent from './OurValuesComponent';
import './../styles/HomePage.css';
function HomePage()
{
    const isSmallScreen = window.innerWidth <= 768; // Define your breakpoint

    return(
        <div> 

          {/* <div style={{'position' : 'relative'}} className="d-flex margin-page background-white">
            <img className="boxesPattern" style={{'position' : 'absolute','left' :'74px' , 'zIndex': '2'}} src="icons/HomePage/TopLeftBoxesPatternNew.svg" alt="Concept"/>
            <img className="buildingIcon" style={{'marginLeft' : '489px' }} src="icons/HomePage/HomePageBuidlingIcon.svg" alt="Design"/>
          </div> */}

          {
             isSmallScreen ? (
                <div className="margin-page background-white">
                <img className="buildingIcon" src="icons/HomePage/HomePageBuidlingIcon.svg" alt="Design"/>
                <img className="boxesPattern" src="icons/HomePage/TopLeftBoxesPatternNew.svg" alt="Concept"/>
                
              </div>
             ):
             (
                <div style={{'position' : 'relative'}} className="d-flex margin-page background-white">
                <img className="boxesPattern" style={{'position' : 'absolute','left' :'74px' , 'zIndex': '2'}} src="icons/HomePage/TopLeftBoxesPatternNew.svg" alt="Concept"/>
                <img className="buildingIcon" style={{'marginLeft' : '489px' }} src="icons/HomePage/HomePageBuidlingIcon.svg" alt="Design"/>
              </div>
             )
          }


        {/* Our services */}

          <div className="margin-page background-white pt-20">
             <img className={`w-100 ${isSmallScreen ? 'px-2':'px-3'}`} src="icons/HomePage/OurServices.svg" alt="Design"/>
          </div>

        <div id="ourservices" className="image-gallery margin-page background-white" style={{'paddingBottom' : '20px' }}>
        <img src="icons/HomePage/boxes pattern grey.svg" alt="Top Left" className="boxes-pattern corner-image top-left" />
         <img src="icons/HomePage/boxes pattern grey.svg" alt="Bottom Right" className="boxes-pattern corner-image bottom-right" />


            {
                isSmallScreen ? (
                <div className="pt-3">
                    <div className="d-flex mb-20">
                        <div className="mr-20" style={{ 'zIndex':'1'}}>
                        <img className="our-service-box" src="icons/HomePage/Site development box.svg" alt="Site development" />
                        </div>
                        <div className="">
                        <img className="our-service-box" src="icons/HomePage/building constructin box.svg" alt="building" />
                        </div>
                    </div>

                    <div className="d-flex mb-20">
                        <div className="mr-20">
                            <img className="our-service-box" src="icons/HomePage/architecture & planning box.svg" alt="architecture" />
                        </div>
                        <div className="">
                            <img className="our-service-box" src="icons/HomePage/interior designing box.svg" alt="interior" />
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="">
                            <img className="our-service-box" src="icons/HomePage/exterior renovation box.svg" alt="exterior " />
                        </div> 
                        <div></div>
                    </div>

                       
                </div>
               ): 
                (
                <div className="content content-our-services ">
                    <div className="row content-our-services-first-row">
                        <div className="image-item">
                        <img className="our-service-box" src="icons/HomePage/Site development box.svg" alt="Site development" />
                        </div>
                        <div className="image-item">
                        <img className="our-service-box" src="icons/HomePage/building constructin box.svg" alt="building" />
                        </div>
                        <div className="image-item">
                        <img className="our-service-box" src="icons/HomePage/architecture & planning box.svg" alt="architecture" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="image-item">
                        <img className="our-service-box" src="icons/HomePage/interior designing box.svg" alt="interior" />
                        </div>
                        <div className="image-item">
                        <img className="our-service-box" src="icons/HomePage/exterior renovation box.svg" alt="exterior" />
                        </div>
                    </div>
                 </div>

                )
            }
            


        </div>

        {/* Assistance */}

        <div id="assistance" className="margin-page background-white">
            <img className={`w-100 ${isSmallScreen ? 'px-2' : ''}`} src="icons/HomePage/end to end assistance title.svg" alt="Concept"/>
        </div>

        <div className="assistance-tile margin-page background-white">
            <img className={`boxes-pattern corner-image top-left ${isSmallScreen ? 'd-none' : ''}`}  src="icons/HomePage/boxes pattern grey.svg" alt="Top Left" />
            <img className="assistance-image" src="icons/HomePage/assistance image.svg" alt="Concept"/> 
            <img className={`${isSmallScreen ? 'd-none' : ''} boxes-pattern corner-image bottom-right`} src="icons/HomePage/boxes pattern grey.svg" style={{ 'marginTop': '75px','marginBottom': '-67px','zIndex':'1'}}  alt="Bottom Right"/>
        </div>

        {/* Building construction */}

        <div style={{'position' : 'relative'}} className="background-white d-flex">
            <img className="w-100" src="icons/HomePage/building construction quote.svg" alt="Concept"/>

            {/* style={{'position' : 'absolute', 'left':'780px','marginTop':'165px'}} ackup for below div */}
            <div className="building-construction-quote" >
                <p className="font-weight-500 quote-text1">"Building dreams for Better Tomorrow”</p>
                <p className="font-weight-500 quote-text2">Let our expertise shape your vision. <br></br> Contact us to construct your future today!</p>
            </div>
        </div>


        {/* Site development quote */}

        {/* style={{'position' : 'relative','paddingTop':'108px'}} */}

        
                {/* style={{'position' : 'absolute', 'left':'113px','marginTop':'165px'}} */}
            

                {
                    isSmallScreen ? 
                    (   
                      <div className="background-white d-flex site-development-section">
                        <div className="site-development-body">
                        <p className="font-weight-500 quote-text1">"Turning Empty Land into Vibrant<br/> Spaces”</p>
                        <p className="font-weight-500 quote-text2">Our expertise lies in bringing out the <br/> potential in every site we develop</p>
                        </div>
                        <div className="site-development-text">
                         <p className="font-weight-500 font-section-heading heading-homepage-sections">SITE DEVELOPMENT</p>
                        </div>

                         <img className="w-100" src="icons/HomePage/Site development quote.svg" alt="Concept"/>
            
                     </div>

                    )
                    :(
                        <div className="background-white d-flex site-development-section">
                         <div className="site-development-body">
                            <p className="font-weight-500 quote-text1">"Turning Empty Land into Vibrant Spaces”</p>
                            <p className="font-weight-500 quote-text2">Our expertise lies in bringing out the potential in  <br></br> every site we develop</p>
                         </div>
                         <div className="site-development-text">
                            <p className="font-weight-500 font-section-heading heading-homepage-sections">SITE DEVELOPMENT</p>
                         </div>
                        <img className="w-100" src="icons/HomePage/Site development quote.svg" alt="Concept"/>
                        </div>
                    )
                }
           
            {/* style={{'position' : 'absolute', 'left':'113px','marginTop':'40px'}} */}
            

        {/* House construction quote */}


        {
            isSmallScreen ? 
            ( 
                <div className="background-white d-flex house-construction-section">
                <img className="w-100" src="icons/HomePage/house construction quote.svg" alt="Concept"/>
                <div className="house-construction-text">
                    <p className="font-weight-500 font-section-heading heading-homepage-sections">HOUSE CONSTRUCTION</p>
                </div>
                <div className="house-construction-body"> 
    
                     {/* 2 lines in mobile */}
                    <p className="font-weight-500 quote-text1">"Crafting Quality, Building Trust."</p>  
                    <p className="font-weight-500 quote-text2">Quality construction you can trust, <br/> because  we care about your future.</p>
                </div>
    
            </div>
            )
            :
            (
              <div className="background-white d-flex house-construction-section">
                <img className="w-100" src="icons/HomePage/house construction quote.svg" alt="Concept"/>
                <div className="house-construction-text">
                    <p className="font-weight-500 font-section-heading heading-homepage-sections">HOUSE CONSTRUCTION</p>
                </div>
                <div className="house-construction-body"> 
                     {/* 2 lines in mobile */}
                    <p className="font-weight-500 quote-text1">"Crafting Quality, Building Trust."</p>  
                    <p className="font-weight-500 quote-text2">Quality construction you can trust, because we <br/> care about your future.</p>
                </div>
    
                 </div>
            )
         }

        {/* architecture & interior design quote  */}


        {
            isSmallScreen ? 
            ( 
                <div className="background-white d-flex architecture-design-section">
                <div className="architecture-design-text">
                    <p className="font-weight-500 font-section-heading heading-homepage-sections">ARCHITECTURE & INTERIOR DESIGN</p>
                </div>
                <div className="architecture-design-body">
                    <p className="font-weight-500 quote-text1">"Transforming Spaces, <br/>Crafting Dreams."</p>
                    <p className="font-weight-500 quote-text2">We specialize in creating Designs that tell <br/>a story and resonate with your style.</p>
                </div>
                <img className="w-100" src="icons/HomePage/architectureInteriorDesignQuote.svg" alt="Concept"/>
    
            </div>
            )
            :
            (
                <div className="background-white d-flex architecture-design-section">
                    <div className="architecture-design-text">
                        <p className="font-weight-500 font-section-heading heading-homepage-sections">ARCHITECTURE & INTERIOR DESIGN</p>
                    </div>
                    <div className="architecture-design-body">
                        <p className="font-weight-500 quote-text1">"Transforming Spaces, Crafting Dreams."</p>
                        <p className="font-weight-500 quote-text2">We specialize in creating Designs that tell a story and <br></br> resonate with your style.</p>
                    </div>
                    <img className="w-100" src="icons/HomePage/architectureInteriorDesignQuote.svg" alt="Concept"/>
                </div>
            )
         }


        {/* our values  */}
        <OurValuesComponent/>
        
               

        </div>
    );
}

export default HomePage;