
function OurValuesComponent(){
    return(
        <>
         {/* <div className="margin-page background-white our-values-div" style={{ position: 'relative', display: 'inline-block' }}>
            <img className="our-values-image" src="icons/HomePage/our values.svg" alt="Concept"/>

        

            <div className="justify-content-around" style={{ position: 'absolute', 'marginTop': '20px', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
                <div style={{ 'marginLeft': '40px' }} className="circle-with-text">
                    <div className="text text3-size font-weight-400">Conduct business with <br /> integrity & fairness</div>
                </div>
                <div className="circle-with-text">
                    <div className="text text3-size font-weight-400">Provide quality products & <br/>services</div>
                </div>
                <div  className="circle-with-text">
                    <div className="text text3-size font-weight-400">Focus on our <br /> customer’s needs</div>
                </div>
                <div  className="circle-with-text">
                    <div className="text text3-size font-weight-400">Be Connected with <br /> customers.</div>
                </div>
                
               
            </div>
        </div>  */}


        <div className="margin-page d-flex background-white our-values-div" style={{ position: 'relative', display: 'inline-block' }}>
             <img className="our-values-image w-100" src="icons/ourValues.svg" alt="Concept"/>
        </div>        
        
        </>

    )
}

export default OurValuesComponent;




